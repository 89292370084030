<!-- Default page that also displays freets -->

<template>
    <main>
      <div
        style="padding-top: 5vh;
        "
      >
        <h3>
          <a href='mailto:robert@mentorlabs.xyz?subject=Question about Mentor Labs&body=Hey Robert,' style="text-decoration: none; color: inherit">
          robert [at] mentorlabs.xyz</a>
          </h3>
      </div>  
      <div class="splash">
        <h1 
          style="color: #6078EA; 
          margin-top: min(5vh, 5.5%); 
          font-weight: 600;
          z-index: 1;
          position: absolute;
          " 
        >
          MENTOR LABS
        </h1>
        <img src="../../assets/logoWhite.png" 
          class="logo"
        />
        
      </div>   
      <div
        style="padding-bottom: 5vh;
        "
      >
        <h2
          style="color: #4F4F4F"
        >
          Acquired by
          <a href="https://www.crimsoneducation.org/" style="text-decoration: underline; color: inherit">
          Crimson Education</a>.
        </h2>
      </div>
    </main>
  </template>
  
  <script>

    console.log('easter egg :D')

    window.addEventListener('load', () => {
      // run after everything is in-place
      const main = document.querySelector('main');
      const h1 = document.querySelector('h1');

      const updateViewportElements = () => {
        main.style.height = `${window.innerHeight}px`;

        if (window.innerWidth < 980) {
          h1.style.fontSize = 'min(10vh, 10vw)';
        } else {
          h1.style.fontSize = 'min(12vh, 12vw)';
        }
      };

      window.addEventListener('resize', updateViewportElements);

      updateViewportElements();
    })

  export default {
    name: 'HomePage',
  };
  </script>
  
  <style scoped>
  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: min(12vh, 12vw);
    letter-spacing: 0.05em;
    margin: 0;
    padding: 0;
  }
  
  h2 {
    font-size: min(3.75vh, 6vw);
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 300;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: min(3vh, 6vw);
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 300;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .splash {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: min(70vh, 70vw);
    filter: drop-shadow(0px 0px 10px rgba(96, 120, 234, 0.20));
    animation: mymove 5s infinite;
  }

  @keyframes mymove {
    50% {filter: drop-shadow(0px 0px 10px rgba(96, 120, 234, 0.50));}
  }
  
  </style>
  